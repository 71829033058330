/* You can add global styles to this file, and also import other style files */
@import '../../../libs/ui/src/lib/styles/theme.scss';
@import '../../../libs/ui/src/lib/styles/base';
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  margin: 0 !important;
}

.lexend-deca {
  font-family: 'Lexend Deca', sans-serif;
}
.montserrat {
  font-family: 'Montserrat', sans-serif;
}
.roboto {
  font-family: 'Roboto', sans-serif;
}
::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-400;
  border-radius: 1.25rem;
}
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-checkbox {
  .mat-ripple {
    @apply rounded-full #{!important};
    left: calc(50% - 13px) !important;
    width: 26px !important;
    height: 26px !important;
    top: calc(50% - 13px) !important;
  }
}
.mat-checkbox-inner-container {
  margin-top: 0.25rem;
}
.mat-checkbox-frame {
  border-radius: 4px !important;
  @apply border-gray-100;
}
.mat-checkbox-background {
  border-radius: 4px !important;
}
.mat-checkbox-checked .mat-checkbox-background{
  @apply bg-primary-700 #{!important};
}

.mat-select-panel{
  position: absolute;
  top: 0.75rem !important;
}

.mat-stepper-custom {
  position: relative !important;
  height: 100% !important;
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
  .mat-horizontal-content-container {
    @apply inset-0;
    padding-bottom: 0 !important;
    position: absolute;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .mat-horizontal-stepper-content {
    height: 100%;
    overflow: auto;
  }
  .step-wrapper {
    @apply mx-auto w-full pt-4 flex flex-col;
    min-height: 100%;
    padding: 0 0.5rem;
  }
}
