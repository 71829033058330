.ui-snackbar-panel {
  font-family: inherit !important;
  min-height: 0 !important;
  @apply p-0 rounded-full bg-transparent #{!important};
  & > div {
    width: 100% !important;
  }
}

.ui-dialog-panel {
  .mat-dialog-container {
    border-radius: 8px !important;
    padding: 0 !important;
    & > * {
      padding: 2rem;
      display: flex;
      flex-direction: column;
    }
  }
}

.mat-button-custom {
  @apply p-0 min-w-0 leading-none #{!important};
  &[disabled="true"] {
    opacity: 0.65;
  }
}
